body {
  @include font-size(16);
  @include line-height(16, 20);

  color: var(--app-text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  font-weight: 400;

  p {
    @include font-size-and-margins(16, 16, 16, $setsize: false, $setlineheight: false);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

h1 {
  @extend %H1;
}

h2 {
  @extend %H2;
}

h3 {
  @extend %H3;
}

h4 {
  @extend %H4;
}

strong,
b {
  font-weight: 700;
}

ul {
  overflow: hidden;

  li {
    list-style-position: inside;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
