%H1 {
  @include font-size-and-margins(36, 44);
}

%H2 {
  @include font-size-and-margins(32, 40);
}

%H3 {
  @include font-size-and-margins(24, 30);
}

%H4 {
  @include font-size-and-margins(20, 26);
}

%PARAGRAPH {
  @include font-size-and-margins(16, 16, 22.5, $setsize: false, $setlineheight: false);
}
