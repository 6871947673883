:root {
  --min-width: 320px;
  --page-heading-height: 75px;
  --sidebar-width: 220px;
}

$font-size-base: 1rem !default;

$min-width: 320px;

$pageHeadingHeight: 68px;
