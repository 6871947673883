.app-logo {
  width: 82px;
  margin-bottom: 8px;
}

.app-content-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0;
  grid-template-areas: 'form chart';
}

.app-content-box-column {
  &--form {
    grid-area: form;
    padding-right: 20px;
  }

  &--chart {
    grid-area: chart;
    padding-left: 20px;
  }
}

.app-content-box-column--form + .app-content-box-column--chart {
  justify-self: stretch;
}
