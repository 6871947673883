* {
  box-sizing: border-box;
}

.startupShareHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  height: 78px;
  background-color: #f1f5f8;
}

.startupShareHeader .col-md-4 {
  padding: 0 20px;
}

.startupShareHeader h1 {
  box-sizing: border-box;
  margin: 0;
  line-height: 1;
  /*height: 120px;*/
  /*font-weight: bold;*/
  font-size: 24px;
  color: #0047ab;
  /*padding: 45px;*/
  text-align: left;
}

.leftMenu img {
  float: left;
  width: 75px;
  margin: 5px;
}

.rightContent {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.startupShareBodyLeft {
  border-right-width: 1px;
  border-right-style: solid;
  padding: 30px;
  border-color: darkgray;
  height: 80vh;
  overflow-y: auto;
}

.startupShareBodyRight {
  padding: 30px;
}

.margin0 {
  margin: 0;
}
.padding0 {
  padding: 0;
}

.boxContent {
  padding: 5px 0 5px 0;
}

.midSection {
  background-color: #f9f9f9;
  border-radius: 13px;
  padding: 20px;
}

.topSection,
.bottomSection {
  padding: 20px;
}

.leftContent {
  text-align: left;
}
.dropClass {
  width: 190px;
  float: left;
  height: 60px;
  border-style: none;
}

.leftMenuParent {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
  width: 200px !important;
  /*height: 100vh;*/
  /*background-color: #211f3b;*/
  /*background: url(../../app/assets/images/sidebar-background.png) no-repeat bottom, linear-gradient(174deg, var(--darkBlue) 0%, var(--darkerBlue) 100%) 0 0 no-repeat padding-box;*/
}

.leftMenu {
  display: flex;
  margin: 0;
  padding: 0;
  /*width: 100px;*/
  /*background-color: #211f3b;*/
  /*background-color: #f0f;*/
}

.leftMenuHeader {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 79px;
  margin-bottom: 10px;
  padding: 8px 10px 0;
  border-bottom: 1px solid var(--softBlue);
  /*background: #61dafb;*/
}

.leftMenuHeader .PimsLogo {
  /*height: 50px;*/
  width: 180px;
  height: 60px;
  /*margin-bottom: 10px;*/
}

.startupShare {
  height: 100vh;
  margin: 0 !important;
}

.startupShareBody {
  height: 1000px;
  overflow-y: scroll;
}
.startupShareBodyLeft button {
  float: right;
  margin-top: 40px;
}

@media only screen and (max-width: 1600px) {
  .startupShareBody {
    height: 600px !important;
  }
  .startupShareBodyLeft {
    overflow-y: auto;
  }
}

/* chart */
.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #000000;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 12px;
  color: #000000;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
.highcharts-axis-labels text,
.highcharts-yaxis text,
.highcharts-xaxis text {
  color: #000000 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.highcharts-xaxis-grid .highcharts-grid-line:first-child {
  display: block;
}
