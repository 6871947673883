html {
  font-family: sans-serif;
}

body {
  @include font-size($font-size-base);

  line-height: 1;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

fieldset {
  border: 0;
}
