:root {
  --white: #ffffff;
  --soft-grey: #f9f9f9;
  --lighter-blue: #f1f5f8;
  --soft-blue: #708fc5;
  --dark-blue: #284d99;
  --cobalt: #0047ab;
  --darker-blue: #211f3b;
  --black: #1e1e1e;

  --sidebar-gradient-start: #01256f;
  --sidebar-gradient-end: #211f3b;

  --app-text-color: var(--black);
  --app-background-color: var(--soft-grey);
}
