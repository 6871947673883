@use 'sass:math';

// Set font size in rems. Fallback in pixels for IE
@mixin font-size($size) {
  //font-size: #{$size}px;
  font-size: #{math.div($size, 10)}rem;
}

// Suffix 'px' to $value if no unit specified.
// Used in font-size-and-margins() mixin
@function add-px-if-unitless($value) {
  @if unitless($value) {
    @return #{$value}px;
  }
  @else {
    @return #{$value};
  }
}

@mixin line-height($fontsize, $leading) {
  $onepointthree: math.div(24, 18);
  @if (math.div($leading, $fontsize) == $onepointthree) {
    line-height: 1.34;
  }
  @else {
    line-height: math.div($leading, $fontsize);
  }
}

// Account for line-height in typography top-/bottom-margin calculations
// By default $bottommargin is 80% of $fontsize; pass in integer of desired px if different
// By default, sets the font-size; pass in $setsize: false if this isn't needed
// By default, sets the line-height; pass in $setlineheight: false if this isn't needed
@mixin font-size-and-margins($fontsize, $leading, $bottommargin:null, $setsize: true, $setlineheight: true) {
  @if $setsize {
    @include font-size($fontsize);
  }

  $leadingcompensation: math.div($leading - $fontsize, 2);
  $topmargin: -($leadingcompensation);

  margin-top: add-px-if-unitless($topmargin);

  @if ($bottommargin) {
    margin-bottom: add-px-if-unitless($bottommargin);
  }

  @if $setlineheight {
    @include line-height($fontsize, $leading);
  }
}
